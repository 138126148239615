import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import thumbnailPlaceholder from '../images/blog-post-placeholder.png'

const PostPreview = ({ date, excerpt, slug, title, thumbnail }) => {
  return (
    <div key={slug} className="post-preview">
      <Link to={`/${slug}`}>
        <img
          src={thumbnail || thumbnailPlaceholder}
          className="img-fluid d-block mx-auto mb-4"
        />
      </Link>
      <h3 className="post-preview--title">
        <Link to={`/${slug}`}>{title}</Link>
      </h3>
      <time className="post-preview--date">{date}</time>
      <p
        className="post-preview--excerpt"
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <Link to={`/${slug}`} className="post-preview--continue">
        Continue Reading
      </Link>
    </div>
  )
}

PostPreview.propTypes = {
  date: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default PostPreview
