import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'

// components
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import PostPreview from '../components/PostPreview'
import { graphql, Link } from 'gatsby'

class BlogIndex extends React.Component {
  renderPosts() {
    const posts = get(this, 'props.pageContext.group')
    const postsList = posts.map(({ node }) => (
      <PostPreview
        key={node.slug}
        slug={node.slug}
        title={node.title}
        date={node.publishedAt}
        excerpt={node.content.childMarkdownRemark.excerpt}
        thumbnail={
          node.featuredImage &&
          node.featuredImage.file &&
          node.featuredImage.file.url
        }
      />
    ))

    return (
      <div className="posts-list col-12 col-sm-11 col-md-10 col-lg-8 col-xl-6 mx-auto">
        {postsList}
      </div>
    )
  }
  renderPagination() {
    const { group, index, first, last, pageCount } = this.props.pageContext
    const previousUrl = index - 1 == 1 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString()
    return (
      <div className="col-12 col-sm-11 col-md-10 col-lg-8 col-xl-6 mb-4 mx-auto d-flex index-pagination">
        <div className="mx-left index-pagination__left text-left">
          {!first ? (
            <Link to={`/${previousUrl}`}>{`< Previous Page`}</Link>
          ) : null}
        </div>
        <div className="mx-auto index-pagination__center text-center">
          {Array(pageCount)
            .fill(null)
            .map((v, i) => {
              const idx = i + 1
              return idx === index ? (
                <span className="mx-1" key={idx}>
                  {idx}
                </span>
              ) : (
                <Link
                  className="mx-1"
                  to={i === 0 ? '/' : `/${idx.toString()}`}
                  key={idx}
                >
                  {idx}
                </Link>
              )
            })}
        </div>
        <div className="mx-right index-pagination__right text-right">
          {!last ? <Link to={`/${nextUrl}`}>{`Next Page >`}</Link> : null}
        </div>
      </div>
    )
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    return (
      <Layout>
        <div className="page-home">
          <Helmet title={siteTitle} />
          <PageHeader title="Blog" />
          {this.renderPosts()}
          {this.renderPagination()}
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query metaDataQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default BlogIndex
